import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useOrder } from "@hooks/api/useSave";
import { useRedStore } from "@store/store";
import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";

export default function IpPatientNumber({ isDisabled }: { isDisabled?: boolean }) {
  const [ipNumber, setIpNumber]: any = useState("");
  const { updateIpPatientNumber, ...rest } = useOrder();
  const ipPatientNumber = useRedStore(state => state.order.ipPatientNumber);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleCancelClick = () => {
    updateIpPatientNumber({
      ipNumber,
      onSuccess: () => {
        onClose();
      },
    });
  };

  useEffect(() => {
    setIpNumber(ipPatientNumber);
  }, [ipPatientNumber]);

  return (
    <Box>
      {/* <Button
        onClick={onOpen}
        borderRadius={"full"}
        variant={"solid"}
        size={"xs"}
        fontWeight={"semibold"}
        colorScheme={"primary"}
        bg={"primary.500"}
        isDisabled={isDisabled}
        _disabled={{
          bg: "primary.300",
          opacity: 0.5,
        }}
        px={4}
      >
        IP Patient Number
      </Button> */}

      <Button
        onClick={onOpen}
        variant={"ghost"}
        colorScheme="primary"
        fontSize={"xs"}
        m={0}
        borderRadius={"full"}
        fontWeight={"semibold"}
        p={0}
        leftIcon={<Icon fontSize={16} icon="fe:edit" />}
        isDisabled={isDisabled}
        _focus={{ borderWidth: 0 }}
      >
        Edit
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={5} bg={"white"}>
          <ModalCloseButton />
          <ModalHeader>IP/UHID Patient Number</ModalHeader>
          <Divider />
          <ModalBody>
            <Box h={"20vh"} overflowY={"scroll"} bg={"whiteAlpha.200"} p={2} mx={-2} borderRadius={"md"}>
              <FormControl isRequired>
                <FormLabel fontSize={"sm"}>Please type Ip/UHID Patient Number</FormLabel>
                <InputGroup size={"md"}>
                  <Input type="text" placeholder="IP/UHID Patient Number" value={ipNumber} fontSize={"md"} fontWeight={"semibold"} onChange={e => setIpNumber(e.target.value)} />
                </InputGroup>
              </FormControl>
            </Box>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button colorScheme={"purple"} size={"sm"} onClick={handleCancelClick} isDisabled={!ipNumber} px={6} isLoading={rest?.isLoading}>
              Update IP/UHID Patient Number
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
